<template>
  <div>
    <Navbar></Navbar> 

    <GenericHeader :title="$t('view.rooms.header')"></GenericHeader>
    <RoomList></RoomList>
  </div>
</template>

<script>
import RoomList from '@/components/RoomList.vue'
import GenericHeader from '@/components/GenericHeader.vue'
import Navbar from '@/components/Navbar.vue'
import { mapActions, mapState } from 'vuex'



export default {
  components: {
    RoomList,
    GenericHeader,
    Navbar,
  },
  computed: {
    ...mapState({
      roomListSocket: state => state.room.roomListSocket,
    })
  },
  methods: {
    ...mapActions([
      'connectRoomListWs',
      'getUserData',
      'closeRoomListWs',
    ])
  },
  beforeRouteEnter (to, from, next) {
    console.debug('beforeRouteEnter fired on room list page');

    next( vm => {
      // make sure that the localStorage has a JWT token by fetching the user's data
      vm.getUserData().then(() =>
        vm.connectRoomListWs()
      );
    });
  },
  beforeRouteLeave (to, from, next) {
    if (this.roomListSocket != undefined) {
      this.closeRoomListWs();
    }
    next();
  }
}
</script>