<template>
<div class="searchbar">
  <b-input-group size="sm">

  <template #prepend>
    <b-input-group-text class="searchbar-icon">
      <b-icon icon="search"></b-icon>
    </b-input-group-text>
  </template>

   <b-form-input 
    class="searchbar-form"
    type="search"
    v-model="searchTerm"
    :placeholder="placeholder"
    @change="submitSearch"
    ></b-form-input>
  </b-input-group>
</div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchTerm: null
    }
  },
  methods: {
    submitSearch() {
      console.log(this.searchTerm);
      this.$emit('search',this.searchTerm);
      // filter user data to display nodes matching search
      // if no nodes match then display in tree box -> no results find
    }
  },
  computed: {
    // when this.searchTerm != null, change the styling to normal
    // text without italicized (differentiate between search input and placeholder styling)
  }
}
</script>