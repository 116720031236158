<template>
  <b-container class="room-list-container">
    <b-row align-v="center" align-h="start" no-gutters>
      <b-col cols="auto" offset-lg="2">

      <!-- Manual reconnection to Ws -->
      <b-button
        size="sm" 
        @click="refreshRoomListWs" 
        variant="primary"
        class="text-default my-2 mr-1">
        {{$t('view.rooms.refresh')}}
      </b-button>

      </b-col>
      <b-col sm="6" md="5" cols="5" lg="3">
      <Searchbar @search = "getSearchTerm" class="searchbar my-1" :placeholder="$t('view.rooms.search')"></Searchbar>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="12" lg="9">
          <b-table 
            class="room-list-table"
            :filter="filter"
            :filter-included-fields="['name']"
            empty-text="No rooms available"
            empty-filtered-text="No matching rooms found"
            no-border-collapse
            fixed
            small
            show-empty
            sticky-header = "350px"
            :dark="(isDarkTheme ? true: false)"
            hover 
            :busy="isBusy"
            :items="rooms"
            primary-key="token" 
            :fields="fields">

            <!------------ GAME STATUS --------------->
            <template #cell(status)="data">

              <b :class="[(data.item.status != $getConst('ROOM_PREGAME_LOBBY')) ? 'text-success' : 'text-danger']">
                {{ (data.item.status != $getConst('ROOM_PREGAME_LOBBY')) ? 'STARTED':'NOT STARTED'}}
              </b>
            </template>


            <!------------ NUMBER OF PLAYERS/MAX NUM OF PLAYERS --------------->
            <template #cell(players)="data">
              {{data.item.players}}/{{data.item.max_players}}
            </template>

            <!------------ BUSY STATE FOR TABLE --------------->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <!------------ FILTERING --------------->
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template #emptyfiltered="scope">
            <h4>{{ scope.emptyFilteredText }}</h4>
            </template>

            <!------------ ROOM EXTRA DETAILS --------------->
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" variant="primary" class="mr-2">
              {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
            </b-button>
          </template>

          <template #row-details="row">
            <b-card 
              :bg-variant="(isDarkTheme ? 'dark': 'light')"
              :text-variant="(isDarkTheme ? 'light': 'dark')">
              <b-row class="mb-1">
                <b-col class="text-sm-left"><b>DESCRIPTION:</b></b-col>
              </b-row>
              <b-row><b-col>{{ row.item.description }}</b-col></b-row>

              <b-row class="mb-2 mt-2">
                <b-col class="text-sm-left"><b>HOST:</b> </b-col>
              </b-row>

              <b-row><b-col>{{ row.item.host }}</b-col></b-row>

              <b-row align-h="end" align-v="center">
                <b-icon v-if="(row.item.password == undefined)"
                  class="mx-2" 
                  icon="unlock" 
                  scale=1.5>
                </b-icon>
                
                <b-icon v-else
                  class="mx-2" 
                  icon="lock" 
                  scale=1.5>
                </b-icon>

                  <b-button 
                    size="sm" 
                    variant="success" 
                    @click="startPlayerGameWs( { status: row.item.status, token: row.item.token, password: null})">
                    Join room
                  </b-button>
              </b-row>
            </b-card>
          </template>

          </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>

import Searchbar from '@/components/Searchbar.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Searchbar,
  },
  data() {
    return {
      filter: null,
      isBusy: false,
      fields: [
        {
          key: 'name',
          // to avoid room name flowing into player header (for a long name)
          tdClass: ["text-break", "text-wrap"]
        },
        // A 'virtual' column made up from two fields
        { 
          key: 'players', 
          label: 'Players' 
        },
        {
          key: 'status',
        },
        {
          key: 'show_details',
          label: 'Details'
        },
      ]
    }
  },
  computed: {
    ...mapState({
      rooms: state => state.room.rooms,
      isDarkTheme: state => state.settings.isDarkTheme,
      roomListSocket : state => state.room.roomListSocket,
    })
  },
  methods: {
    ...mapActions([
      'connectPlayerGameWs',
      'connectRoomListWs',
      'closeRoomListWs'
    ]),

    refreshRoomListWs() {
      // If the socket isn't undefined or the socket is closed, reconnect
      if (this.roomListSocket == undefined || this.roomListSocket.readyState == this.$getConst['WS_CLOSED']) {
        this.connectRoomListWs();
      }
    },

    getSearchTerm(e) {
      this.filter = e;
    },

    startPlayerGameWs( room ) {

      if (room.status != this.$getConst('ROOM_PREGAME_LOBBY')) {
        alert('Cannot join a game that has already started!');
        return;
      }

      if (this.roomListSocket != undefined) {
        this.closeRoomListWs();
      }

      this.connectPlayerGameWs(room.token)
        .then(() => {
          var route = this.$t('view.pregame.route') + room.token;
          this.$router.push(route);
        })
        .catch(() => {
          console.error('unable to connect to playerGameWs')
          alert('Unable to join room');
        })
    }
  } 
}
</script>